import { Flex, Heading, Image, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useMemo } from 'react';

import { motionFadeUpSlowProps } from '_/configs/motion-presets';

import { MotionBox } from '@/components/chakra-ui/framer-motion';
import { GeneralSection, SectionSize, SectionType } from '@/layout';
import { createLog, useGridProps } from '@/utils';
import { t, tl } from '@/utils/i18n';

import type { BoxProps } from '@chakra-ui/react';
import type { IconType } from 'react-icons';
const log = createLog(`WorksSection`);

interface FeatureProps {
  idx: number;
  iconPath: string;
  title: string;

  // OPTIONAL
  iconSvg?: ReactElement;
  descriptions?: string[];
  boxProps?: BoxProps;
  floatingDelayBase?: number;
  floatingDelayMobile?: number;
  floatingDelayMult?: number;
  floatingDuration?: number;
  icon?: IconType;
}

const Feature = ({
  idx,
  iconPath,
  title,

  // OPTIONAL
  descriptions,
  boxProps,
  floatingDelayBase = 0.2,
  floatingDelayMobile = 0.5,
  floatingDelayMult = 0.3,
  floatingDuration = 0.6,
  icon: FeatureIcon,
}: React.PropsWithChildren<FeatureProps>) => {
  const { isMobile } = useGridProps();

  useEffect(() => {
    log.debug(`FeatureIcon '${FeatureIcon?.name}' is loaded but ignored`);
  }, [FeatureIcon?.name]);

  const floatingDelay = useMemo(
    () =>
      isMobile ? floatingDelayMobile : floatingDelayBase + floatingDelayMult * (idx + 1),
    [floatingDelayBase, floatingDelayMobile, floatingDelayMult, idx, isMobile],
  );

  return (
    <MotionBox
      key={`works-section-feature-${title}`}
      p="0"
      {...motionFadeUpSlowProps}
      {...boxProps}
      transition={{
        delay: floatingDelay,
        duration: floatingDuration,
      }}
    >
      <Flex align="center" flexDir="column" justify="center" textAlign="left">
        <Image src={iconPath} alt={`icon_${title}`} maxH="80px" mb="0.75rem" />
        <Heading as="h3" textStyle="content.title-sm" my="0.5rem">
          {title}
        </Heading>
        <Text textStyle="content.body-md" fontSize={{ base: `1rem`, md: `1.05rem` }}>
          <UnorderedList ml={{ base: `16px`, md: `4px` }}>
            {descriptions?.map((desc, descIdx) => (
              <ListItem key={`worksSectionDesc${desc}Idx${descIdx}`}>{desc}</ListItem>
            ))}
          </UnorderedList>
        </Text>
      </Flex>
    </MotionBox>
  );
};

export const WorksSection = () => {
  return (
    <GeneralSection
      name="works"
      // OPTIONAL
      px={{ base: `0.75rem`, md: `0` }}
      containerProps={{ maxW: `90rem` }}
      sectionSize={SectionSize.LG}
      sectionType={SectionType.LIGHT}
      title={t(`pages.home.sections.works.title`)}
      titleTextUnderlineDividerProps={{ underlineMarginBottom: `1rem` }}
      description={tl(`pages.home.sections.works.descriptions`)}
    >
      <Flex
        flexDir={{ base: `column`, md: `row` }}
        gap={{ base: `4.5rem`, md: `3rem` }}
        pt={{ base: `2rem`, md: `0.75rem` }}
      >
        <Feature
          idx={0}
          iconPath="/pages/home/sections/works/icon_1.png"
          title={t(`pages.home.sections.works.item1.title`)}
          descriptions={tl(`pages.home.sections.works.item1.descriptions`)}
        />
        <Feature
          idx={1}
          iconPath="/pages/home/sections/works/icon_2.png"
          title={t(`pages.home.sections.works.item2.title`)}
          descriptions={tl(`pages.home.sections.works.item2.descriptions`)}
        />
        <Feature
          idx={2}
          iconPath="/pages/home/sections/works/icon_3.png"
          title={t(`pages.home.sections.works.item3.title`)}
          descriptions={tl(`pages.home.sections.works.item3.descriptions`)}
        />
        <Feature
          idx={3}
          iconPath="/pages/home/sections/works/icon_4.png"
          title={t(`pages.home.sections.works.item4.title`)}
          descriptions={tl(`pages.home.sections.works.item4.descriptions`)}
        />
        <Feature
          idx={4}
          iconPath="/pages/home/sections/works/icon_5.png"
          title={t(`pages.home.sections.works.item5.title`)}
          descriptions={tl(`pages.home.sections.works.item5.descriptions`)}
        />
      </Flex>
    </GeneralSection>
  );
};
