import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './specialty.module.scss';

export interface SlideItem {
  titleIcon: ReactNode;
  titles: string[];
  body: ReactNode;
  bgPath: string;
  url: string;

  // OPTIONAL
  bgAlpha?: number;
}

export interface SlideProps {
  active: boolean;
  handleClick: (
    title: string,
    idx: number,
    url: string,
  ) => React.MouseEventHandler<HTMLDivElement>;
  handleFocus: (title: string, idx: number) => React.FocusEventHandler<HTMLDivElement>;
  handleHover: (title: string, idx: number) => React.MouseEventHandler<HTMLDivElement>;
  idx: number;
  item: SlideItem;
}

const Slide = ({
  active,
  handleClick,
  handleFocus,
  handleHover,
  idx,
  item,
}: SlideProps): JSX.Element => {
  const {
    titles = `<TITLE>`,
    body = `Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    url = `/`,
  } = item;

  return (
    <div
      key={titles[0]}
      className={cn(styles.slide, { [styles.active]: active })}
      role="button"
      tabIndex={idx}
      onClick={handleClick(titles[0], idx, url)}
      onMouseOver={handleHover(titles[0], idx)}
      onFocus={handleFocus(titles[0], idx)}
    >
      <div className={styles.titleWrap}>
        <div className={styles.titleWrap1}>
          <div className={styles.titleIcon}>{item.titleIcon}</div>
          <div className={styles.text}>{titles[0]}</div>
        </div>
        <div className={styles.titleWrap2}>
          <div className={styles.titleIcon}>{item.titleIcon}</div>
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <h3 className={styles.title}>
              <div className={styles.titleIcon}>{item.titleIcon}</div>
              {titles[1]}
            </h3>
            <p className={styles.body}>{body}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
