import { Box, Text, chakra, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import { TextUnderline } from '@/components';
import { Container, ResponsiveBr } from '@/layout';
import { t } from '@/utils/i18n';

const defLocal = {
  bgAlpha: `0.2`,
  textShadow: `3px 3px 10px rgb(64 64 64 / 5%)`,
  message: {
    maxW: `560px`,
    mx: `auto`,
    fontSize: { base: `lg`, lg: `xl` },
  },
};

export const MainBannerSection = () => (
  <Box
    as="section"
    h="100vh"
    display="flex"
    alignItems="center"
    bg={`linear-gradient(0deg, rgba(0, 0, 0, ${defLocal.bgAlpha}), rgba(0, 0, 0, ${defLocal.bgAlpha})), url('/pages/home/banner.jpg')`}
    bgPos="center"
    bgSize="cover"
    bgAttachment="fixed"
  >
    <Container pos="relative">
      <Box
        textAlign="center"
        color={useColorModeValue(`whiteAlpha.900`, `whiteAlpha.900`)}
        pos="relative"
        right={{ base: `2vw`, md: `3vw` }}
      >
        <chakra.h1
          textStyle="main-banner.heading"
          maxW={{ base: `8.3ch`, lg: `9ch` }}
          mb="16px"
          mx="auto"
          textAlign="right"
        >
          <Box
            as="div"
            color={useColorModeValue(`whiteAlpha.900`, `whiteAlpha.900`)}
            textShadow={defLocal.textShadow}
          >
            {t(`pages.home.sections.main-banner.title.main1`)}
          </Box>
          <Box
            as="div"
            color={useColorModeValue(`whiteAlpha.900`, `whiteAlpha.900`)}
            textShadow={defLocal.textShadow}
          >
            <Box as="span" fontWeight="semibold" mr="0.9rem">
              +
            </Box>
            {t(`pages.home.sections.main-banner.title.main2`)}
          </Box>
          <Box
            as="div"
            color={useColorModeValue(`whiteAlpha.900`, `whiteAlpha.900`)}
            textShadow={defLocal.textShadow}
          >
            <Box as="span" fontWeight="semibold" mr={{ base: `2.2rem`, md: `3.55rem` }}>
              =
            </Box>
            <TextUnderline colorLight="whiteAlpha.900">
              {t(`pages.home.sections.main-banner.title.brand`)}
            </TextUnderline>
          </Box>
        </chakra.h1>
        <Text
          as="span"
          textStyle="main-banner.description"
          color={useColorModeValue(`whiteAlpha.900`, `whiteAlpha.900`)}
          mt={6}
          pos="relative"
          left={{ base: `2vw`, md: `3vw` }}
          fontSize={{ md: `2xl` }}
          // fontWeight="bolder"
        >
          {t(`pages.home.sections.main-banner.message1`)}
        </Text>
        <ResponsiveBr breakpoint="md" />
        {/* <Text
          as="span"
          textStyle="main-banner.description"
          color={useColorModeValue(`whiteAlpha.900`, `whiteAlpha.900`)}
        >
          {t(`pages.home.sections.main-banner.message2`)}
        </Text> */}
      </Box>
    </Container>
  </Box>
);
