import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import { useRouter } from 'next/dist/client/router';
import React, { useMemo } from 'react';

import { NODE_ENV, NodeEnv } from '_/configs';

import { GeneralSection, ResponsiveBr } from '@/layout';
import { createLog, useStateL } from '@/utils';

import Slide, { SlideItem } from './slide';
import styles from './specialty.module.scss';

const log = createLog(`SpecialtySection`);

export interface SpecialtySectionProps {
  slideItems: SlideItem[];
}

const SpecialtySection = ({ slideItems }: SpecialtySectionProps) => {
  const router = useRouter();

  const [activeSlideIdx, setActiveSlideIdx] = useStateL<number | undefined>(
    undefined,
    `activeSlideIdx`,
  );

  const handleMouseEnterSlide = () => {
    if (NODE_ENV !== NodeEnv.PROD) log.debug(`onMouseEnter slides`);
  };

  const handleMouseLeaveSlide = () => {
    if (NODE_ENV !== NodeEnv.PROD) log.debug(`onMouseLeave slides`);
    setActiveSlideIdx(undefined);
  };

  const content = useMemo(() => {
    const result: React.ReactNode[] = [];

    const handleClickSlide =
      (title: string, i: number, url: string) => (e?: React.MouseEvent) => {
        e?.preventDefault();
        log.debug(`handleClickSlide(${title}, ${i}, ${url})`);
        router.push(url);
      };

    const handleFocusSlide = (title: string, i: number) => (e?: React.FocusEvent) => {
      e?.preventDefault();
      log.debug(`handleFocusSlide(${title}, ${i})`);
    };

    const handleHoverSlide = (title: string, i: number) => (e?: React.MouseEvent) => {
      e?.preventDefault();
      log.debug(`handleHoverSlide(${title}, ${i})`);
      setActiveSlideIdx(i);
    };

    slideItems?.forEach((item, i) =>
      result.push(
        <Slide
          key={item.titles[0]}
          idx={i}
          item={slideItems[i]}
          active={activeSlideIdx === i}
          handleClick={handleClickSlide}
          handleFocus={handleFocusSlide}
          handleHover={handleHoverSlide}
        />,
      ),
    );
    return result;
  }, [activeSlideIdx, router, setActiveSlideIdx, slideItems]);

  return (
    <GeneralSection
      name="specialty"
      // OPTIONAL
      title="Service Scope"
      description={
        <>
          디지털 마케팅에 가장 최적화된{` `}
          <ResponsiveBr breakpoint="md" />
          서비스를 제공합니다.
        </>
      }
    >
      <Box px="3rem" pt={{ '3xl': `0.5rem` }} pb={{ '3xl': `0.5rem` }}>
        <Box
          className={cn(styles.slides, { [styles.active]: activeSlideIdx !== undefined })}
          onMouseEnter={handleMouseEnterSlide}
          onMouseLeave={handleMouseLeaveSlide}
          h={{ 'base': `55vh`, '3xl': `50vh` }}
        >
          {content}
        </Box>
      </Box>
    </GeneralSection>
  );
};

export default SpecialtySection;
