import { Box, Img, keyframes } from '@chakra-ui/react';

import { GeneralSection, SectionSize } from '@/layout';
import { t } from '@/utils';

const moveSlideshow = keyframes({
  '0%': {
    transform: `translateX(0%)`,
  },
  '100%': {
    transform: `translateX(-50%)`,
  },
});

export const PartnersSection = () => {
  return (
    <GeneralSection
      name="partners"
      // OPTIONAL
      sectionSize={SectionSize.LG}
      title={t(`pages.home.sections.partners.title`)}
    >
      <Box boxSizing="border-box" m="0 auto" maxW="1200px" p="0 10px">
        <Box
          className="slideshow"
          h="380px"
          m="0 auto"
          maxW="1180px"
          overflow="hidden"
          pos="relative"
          w={{ base: `100%`, md: `80vw` }}
        >
          <Img
            className="shadow left"
            src="/logos/slideshow_shadow.png"
            alt="Left Shadow"
          />
          <Img
            className="shadow right"
            src="/logos/slideshow_shadow.png"
            alt="Right Shadow"
          />
          <Img
            className="logo"
            animation={`${moveSlideshow} 40s linear infinite`}
            maxW="none"
            src="/logos/slideshow_partners.jpg"
            alt="Partner Logo Slideshow"
          />
        </Box>
      </Box>
    </GeneralSection>
  );
};
