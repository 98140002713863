import { Divider, Flex, Heading, Image, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import { motionFadeUpSlowProps } from '_/configs/motion-presets';

import { MotionBox } from '@/components/chakra-ui/framer-motion';
import { GeneralSection, ResponsiveBr, SectionSize, SectionType } from '@/layout';

interface FloatingBoxProps {
  iconPath: string;
  title: string;
  text: string;
}

const FloatingBox = ({ iconPath, title, text }: FloatingBoxProps) => (
  <MotionBox
    key={`stepsSectionBox${title}`}
    bg={useColorModeValue(`white`, `gray.700`)}
    fontSize="2rem"
    p={{ base: `1.2rem`, md: `1.8rem 4.4rem` }}
    rounded="xl"
    shadow="md"
    transitionProperty="box-shadow"
    transitionDuration="normal"
    transitionTimingFunction="ease-in-out"
    _hover={{ shadow: `xl` }}
    {...motionFadeUpSlowProps}
  >
    <Flex align="center" minH="5.75rem">
      <Image
        src={iconPath}
        alt={`icon_${title}`}
        w={{ base: `52px`, md: `80px` }}
        mr={{ base: `0.95rem`, md: `3rem` }}
      />
      <Flex align="flex-start" flexDir="column" w="74%">
        <Heading
          as="h3"
          flex="1 1"
          textAlign="left"
          textStyle="content.title-sm"
          fontSize={{ base: `0.9rem`, md: `1.4rem` }}
        >
          {title}
        </Heading>
        <Divider
          color="gray.700"
          height="2px !important"
          mt="0.2rem"
          mb="0.4rem"
          opacity="0.2"
        />
        <Text
          as="span"
          flex="1 1"
          textStyle="content.body-md"
          fontSize={{ base: `0.85rem`, md: `1.1rem` }}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  </MotionBox>
);

export const StepsSection = () => (
  <GeneralSection
    name="steps"
    // OPTIONAL
    title="Core Value"
    description={
      <>
        이노앤엠만의 체계화된 마케팅 계획으로 <ResponsiveBr breakpoint="md" />
        확실한 결과를 보장해드립니다.
      </>
    }
    sectionSize={SectionSize.MD}
    sectionType={SectionType.LIGHT}
  >
    <Flex flexDir="column" gap={{ base: `2.5rem`, md: `3rem` }} maxW="768px" mx="auto">
      <FloatingBox
        iconPath="/pages/home/sections/steps/icon_1.png"
        title="CUSTOMER WITH"
        text="클라이언트의 성공은 곧 이노앤엠의 성장을 위한 자양분입니다."
      />
      <FloatingBox
        iconPath="/pages/home/sections/steps/icon_2.png"
        title="PROGRESSIVELY RESEARCH"
        text="보다 효과적인 솔루션을 이끌어내기 위하여 끊임없이 연구합니다."
      />
      <FloatingBox
        iconPath="/pages/home/sections/steps/icon_3.png"
        title="TRENDY MARKETING"
        text="변화무쌍한 시대에 맞춰 트렌드를 주도하는 마케팅을 실현합니다."
      />
      <FloatingBox
        iconPath="/pages/home/sections/steps/icon_4.png"
        title="HIGH-QUALITY SERVICE"
        text="클라이언트와 소비자 모두가 만족하는 HIGH-QUALITY의 마케팅 서비스를 제공합니다."
      />
    </Flex>
  </GeneralSection>
);
