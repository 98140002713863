import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Icon,
  Link,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { GeneralSection } from '@/layout';
import { SlideItem } from '@/views/home/sections/specialty/slide';

import type { GeneralSectionProps } from '@/layout';

interface SpecialtyAccordionItemProps {
  idx: number;
  slideItem: SlideItem;

  // OPTIONAL
  color?: string;
  rounded?: AccordionItemProps[`rounded`];
}

const SpecialtyAccordionItem = ({
  idx,
  slideItem,

  // OPTIONAL
  color = `white`,
  rounded = `md`,
}: SpecialtyAccordionItemProps) => {
  const {
    titleIcon,
    titles = `<TITLE>`,
    body = `Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    url = `/`,
  } = slideItem;

  const bgAlpha = useMemo(() => slideItem.bgAlpha ?? 0.45, [slideItem]);

  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <Box
          bg={`linear-gradient(
          to bottom,
          rgba(23, 24, 32, ${bgAlpha}),
          rgba(23, 24, 32, ${bgAlpha})
        ), url('${slideItem.bgPath}')`}
          bgPos="70% center"
          bgSize="cover"
          color={color}
          filter={isExpanded ? `grayscale(0.2)` : `grayscale(0.9)`}
          py="0.75rem"
          px="1rem"
          roundedBottom={idx === 3 && rounded}
          roundedTop={idx === 0 && rounded}
        >
          <AccordionButton>
            <Flex
              align="center"
              flex="1"
              fontSize="1rem"
              gap="0.4rem"
              textAlign="left"
              textShadow="0 0 1px rgba(255, 255, 255, 0.4)"
              transform={isExpanded ? `translateY(1.5rem)` : ``}
              transitionDuration="normal"
              transitionProperty="all"
              transitionTimingFunction="ease-in-out"
            >
              {titleIcon}
              {titles[0]}
            </Flex>
            <Icon
              as={ChevronLeftIcon}
              transitionDuration="normal"
              transitionProperty="all"
              transitionTimingFunction="ease-in-out"
              transform={isExpanded ? `translateY(1.5rem) rotate(-90deg)` : ``}
              w={6}
              h={6}
            />
          </AccordionButton>
          <AccordionPanel
            fontSize="0.9rem"
            fontWeight="700"
            p="1.5rem 0rem"
            textShadow="0 0 1px rgba(255, 255, 255, 0.4)"
          >
            <Divider my="1.25rem" color="#eee" opacity={0.6} />
            <Link href={url}>{body}</Link>
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
};

export interface SpecialtyMobileSectionProps {
  slideItems: SlideItem[];

  // OPTIONAL
  sectionProps?: GeneralSectionProps;
}

const SpecialtyMobileSection = ({
  slideItems,

  // OPTIONAL
  sectionProps,
}: SpecialtyMobileSectionProps) => {
  return (
    <GeneralSection title="Service Scope" {...sectionProps}>
      <Accordion
        defaultIndex={[0]}
        transitionProperty="all"
        transitionDuration="ultra-fast"
        transitionTimingFunction="cubic-bezier(0.19, 0.72, 0.39, 0.78)"
      >
        {slideItems.map((item, i) => (
          <SpecialtyAccordionItem key={i} idx={i} slideItem={item} />
        ))}
      </Accordion>
    </GeneralSection>
  );
};

export default SpecialtyMobileSection;
