import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { BsChatSquareDots, BsFillBarChartLineFill, BsInstagram } from 'react-icons/bs';
import { FaTv } from 'react-icons/fa';

import { Footer } from '@/layout';
import { PageLayout } from '@/layouts';
import { t, useGridProps, useLog, useStateL } from '@/utils';
import { ContactSection } from '@/views/common/sections';
import {
  MainBannerSection,
  PartnersSection,
  SpecialtyMobileSection,
  SpecialtySection,
  StepsSection,
  WorksSection,
} from '@/views/home/sections';
import { SlideItem } from '@/views/home/sections/specialty/slide';

const UNIT_NAME = `HomePage`;

const slideItems: SlideItem[] = [
  {
    titleIcon: <BsChatSquareDots />,
    titles: [`바이럴 마케팅`, `바이럴 마케팅 | Viral Marketing`],
    body: (
      <Box>
        <Text mb="0.75rem">
          온라인 상의 커뮤니티 공간을 통해 자사 브랜드의 가치를 높일 수 있는 마케팅
          방법이며, 광고주와 소비자 간의 양방향 소통으로 신뢰를 쌓을 수 있다는 장점이
          있습니다.
        </Text>
        <UnorderedList>
          <ListItem>블로그 체험단</ListItem>
          <ListItem>인스타그램 체험단</ListItem>
          <ListItem>카페&커뮤니티 입소문 마케팅</ListItem>
        </UnorderedList>
      </Box>
    ),
    bgPath: `/pages/business/viral/sections/about/bg_1.webp`,
    url: `/business/viral`,
  },
  {
    titleIcon: <BsInstagram />,
    titles: [`SNS 운영대행`, `SNS 운영대행 | SNS Marketing`],
    body: (
      <Box>
        <Text mb="0.75rem">
          브랜드 아이덴티티를 반영한 컨셉 및 운영 전략을 수립하고 소비자와의 양방향
          커뮤니케이션으로 브랜드 채널을 운영해 드립니다.
        </Text>
        <UnorderedList>
          <ListItem>페이스북 브랜드 채널 운영대행</ListItem>
          <ListItem>인스타그램 브랜드 채널 운영대행</ListItem>
          <ListItem>블로그 브랜드 채널 운영대행</ListItem>
        </UnorderedList>
      </Box>
    ),
    bgPath: `/pages/business/sns/sections/about/bg_1.png`,
    url: `/business/sns`,
  },
  {
    titleIcon: <FaTv />,
    titles: [`언론 송출`, `언론 송출 | Media Transmission`],
    body: (
      <Box>
        <Text mb="0.75rem">
          기업 및 브랜드에 있어서 가장 직접적인 PR방법으로 기업의 이슈를 보도매체 통하여
          뉴스기사 송부하여 주요 포털 사이트인 네이버, 다음, 네이트에 클라이언트 기업의
          브랜드 및 상품을 노출시킵니다.
        </Text>
        <UnorderedList>
          <ListItem>
            포털 사이트 뉴스 섹션에 노출되는 국내 주요 150여개 언론사와 제휴
          </ListItem>
          <ListItem>전문성으로 무장한 업종별 맞춤 고품질 원고자의 콘텐츠 생성</ListItem>
        </UnorderedList>
      </Box>
    ),
    bgPath: `/pages/business/media/sections/about/bg_1.png`,
    url: `/business/media`,

    // OPTIONAL
    bgAlpha: 0.6,
  },
  {
    titleIcon: <BsFillBarChartLineFill />,
    titles: [`종합 마케팅`, `종합 마케팅 | Comprehensive Marketing`],
    body: (
      <Box>
        <UnorderedList>
          <ListItem>모니터링 및 리서치</ListItem>
          <ListItem>유튜브 체험단</ListItem>
          <ListItem>미스터리 쇼퍼</ListItem>
          <ListItem>영상 촬영</ListItem>
          <ListItem>연예인 & 셀럽 PPL</ListItem>
          <ListItem>옥외광고</ListItem>
        </UnorderedList>
      </Box>
    ),
    bgPath: `/pages/business/marketing/sections/about/heading_5.png`,
    url: `/business/marketing`,
  },
];

const HomePage = () => {
  const log = useLog(UNIT_NAME);
  const { isMobile } = useGridProps();

  const [showNextPageButton, setShowNextPageButton] = useStateL(
    false,
    `showNextPageButton`,
  );
  const handleContactSectionInView = useCallback(
    (inView: boolean, entry: IntersectionObserverEntry) => {
      log.debug(`inView: ${inView}, inViewEntry: ${JSON.stringify(entry)}`);
      setShowNextPageButton(inView);
    },
    [log, setShowNextPageButton],
  );

  const [specialtySection, setSpecialtySection] = useState<ReactNode>(null);
  useEffect(() => {
    // prettier-ignore
    switch (isMobile) {
      case true: setSpecialtySection(<SpecialtyMobileSection slideItems={slideItems} />); break;
      case false: setSpecialtySection(<SpecialtySection slideItems={slideItems} />); break;
      default: break;
    }
  }, [isMobile]);

  return (
    <PageLayout
      nextPageHref="/about"
      seoProps={{
        title: t(`pages.home.seo.title`),
        description: t(`pages.home.seo.description`),
      }}
      // showFloatingButtons
      showNextPageButton={showNextPageButton}
    >
      <MainBannerSection />
      <StepsSection />
      {specialtySection}
      <WorksSection />
      <PartnersSection />
      <ContactSection handleInView={handleContactSectionInView} />
      <Footer />
    </PageLayout>
  );
};

export default HomePage;
